<template>
    <div>
        <!-- Page Title
		============================================= -->
		<section id="page-title">

			<div class="container clearfix">
				<h1>Blog</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item"><router-link to="/blog">Blog</router-link></li>
				</ol>
			</div>

		</section><!-- #page-title end -->

        <section id="content">
			<div class="content-wrap">
				<div class="container clearfix">

					<div class="row gutter-40 col-mb-80">
						<!-- Post Content
						============================================= -->
						<div class="postcontent col-lg-9">

							<div class="single-post mb-0">

								<!-- Single Post
								============================================= -->
								<div class="entry clearfix">

									<!-- Entry Title
									============================================= -->
									<div class="entry-title">
										<h2>Google Analytics UA vs GA4: Which is Better?</h2>
									</div><!-- .entry-title end -->

									<!-- Entry Meta
									============================================= -->
									<div class="entry-meta">
										<ul>
											<li><i class="icon-calendar3"></i> 1st September 2022</li>
											<li><a><i class="icon-user"></i> Phil Indi Balanza</a></li>
										</ul>
									</div><!-- .entry-meta end -->

									<!-- Entry Image
									============================================= -->
									<div class="entry-image">
										<img src="/assets/images/blog/ga.png" alt="Analytics" class="nostyle" style="width: 75%; height: 75%">
									</div><!-- .entry-image end -->

									<!-- Entry Content
									============================================= -->
									<div class="entry-content mt-0">
                                        <p>On March 2022, Google announced that by July 2023, Google Analytics' Universal Analytics (UA) will no longer be collecting data. As a result, almost all websites will have to migrate to the less popular GA4 (Google Analytics 4). This new update will also change a lot of metrics tracking for SEO specialists and website owners.</p>
                                        <p>Since the switch to GA4 is inevitable, we rounded up the main differences between these two tracking systems to help you navigate this critical change.</p>
                                        <h3>Privacy and Security</h3>
                                        <p><a href="https://support.google.com/analytics/answer/10089681?hl=en">GA4's</a> most significant modification is removing IP address collection and storage. Unless you went in and masked them, IP addresses were visible in Universal Analytics. This is the first of many changes to safeguard user data.</p>
                                        <p>Another modification is GA4's <a href="https://support.google.com/analytics/answer/9940393?hl=en&ref_topic=2919631">User Data Purging</a>. If you need to delete data from the Analytics servers for any reason, you can do so by issuing a data-deletion request.</p>
                                        <p>Admins can also select the duration of user-specific data storage using the data retention options. The time frame is two months by default, but you can increase this to 14 months if you like. (Note: This only applies to user-specific data; it does not mean you will only have access to data for 14 months.)</p>
                                        <h3>Data Gathering</h3>
                                        <p>Universal Analytics relies on gathering "cookie-based" data. The visitor's session begins when a cookie is placed in their browser, allowing you to keep track of their online activities. Each hit a user makes depends on that session; without the session, it would be impossible to link any hits in Universal Analytics to any particular user.</p>
                                        <p>Google Analytics 4 continues to employ first-party cookies and Google signals in addition to cookies to identify between different users. As a result, everything that occurs when a visitor is on your website or app is logged as an event instead of tracking sessions and hits.</p>
                                        <h3>Reports Customization</h3>
                                        <p>Google Analytics' UA allows you to transmit three parameters with each event. This was too limiting, preventing most data analysts from generating custom reporting. GA4 now will enable you to submit 25 parameters with each event. You may understand why the change was required by multiplying the number of events one can send to GA4 by an infinite number. Only GA4 caught up to other analytical tools.</p>
                                        <p>In addition, it is now possible to investigate a wide range of occurrences in a funnel or path exploration. Through GA4's Exploration Section, you can generate any table for a streamlined report depending on your need.</p>
                                        <h3>Data Attribution</h3>
                                        <p>UA is known for only recording the last hit that leads to an action. For example, if a user visits a page through an ad for the first time but then returns to the same page through direct searches made on Google, the attribution is placed on the direct hit. Though an admin can still track data through assisted conversions tracking, it can only generate data based on conversions.</p>
                                        <p>In GA4, an <a href="https://support.google.com/analytics/answer/10596866?hl=en#zippy=%2Cin-this-article">information-driven attribution mechanism</a> is set as the default. This provides better ways of investigating how various models would assign conversions. Even better, you may switch your default model to a different one, which was not allowed with UA. Any changes you make to your attribution model take effect immediately. </p>
                                        <p>Everything in a data-driven attribution approach is based on the distinction between touchpoints that convert and those that do not. Therefore, machine learning is used by the Attribution Data-driven approach to identify the significance of various touchpoints and to determine how they affect conversion outcomes.</p>
                                        <h3>Metrics</h3>
                                        <p>Almost every report in Universal Analytics included behavior metrics. These metrics included the bounce rate, the number of pages per session, and the average session duration.</p>
                                        <p>In Google Analytics 4, these metrics were replaced with new "engagement" metrics: </p>
                                        <ul class="indent" style="list-style:none">
                                            <li><p><b>Engaged session.</b>This records visits that lasted longer than 10 seconds. This also records visits that had two or more viewed pages or triggered a conversion.</p></li>
                                            <li><p><b>Average engagement time per session.</b>According to Google, this is the average session-level user involvement time. In other words, the duration of actual page interaction (such as scrolling, etc.), where the page serves as the main window being viewed on the screen.</p></li>
                                            <li><p><b>Engagement rate.</b>The ratio of engaged sessions to all sessions is known as the engagement rate. If you had 185 qualified engagements out of every 1000 visits, your engagement rate is 18.5%.</p></li>
                                        </ul>
                                        <p>As business owners, it is essential to know the stark differences between GA4 and UA. Though the changes may seem a better option, many SEO specialists and web admins are still trying to catch up with the incoming update. </p>
                                        <p>At eFlexervices, we ensure that our stakeholders are updated with significant changes that affect their businesses. That said, during this major transition from UA to GA4, we're constantly on our toes, ensuring to keep learning and training our personnel to provide optimal service consistently. With eFlex, you get the most reliable professional SEO VA services from an experienced team that knows SEO inside and out.</p>
                                    </div>
								</div><!-- .entry end -->

								<!-- Post Author Info - Phil
								============================================= -->
								<div class="card">
									<div class="card-header"><strong>Posted by Phil Indi Balanza</strong></div>
									<div class="card-body">
										<div class="author-image">
											<img src="/assets/images/author/phil.jpg" alt="Image" class="rounded-circle">
										</div>
										<p><i><a href="https://www.linkedin.com/in/phil-indi-kiaco-balanza-7a4ba912a/">Phil</a>  is a partner success manager at eFlexervices and is working with <a href="https://victoriousseo.com/">Victorious SEO</a> , which uses eFlex to assist them with their business operations, including running analytics and compiling reports. He has worked at eFlex for three years, and before managing the partnership with Victorious, he played a significant role in pioneering new partnerships for eFlex, including Manscaped. In addition, Phil continues to help foster project growth and people development at eFlex. </i></p>
									</div>
								</div><!-- Post Single - Author End -->

								<div style="padding-top:20px">
                                    To schedule a call with Phil, <a href="https://calendly.com/geteflex/15min">click here</a>.
                                </div>

								<!-- Post Navigation
								============================================= -->
								<div class="row justify-content-between col-mb-30 post-navigation" style="padding-top:20px">
									<div class="col-12 col-md-auto text-center">
										<router-link to="/blog/highly-skilled-talent-in-the-philippines">&lArr; Next Post</router-link>
									</div>

									<div class="col-12 col-md-auto text-center">
										<router-link to="/blog/debunking-stigma-around-outsourced-sales">Previous post &rArr;</router-link>
									</div>
								</div><!-- .post-navigation end -->

								<div class="line"></div>

								<!-- <h4>Related Posts:</h4>

								<div class="related-posts row posts-md col-mb-30">

                                    <div class="entry col-12 col-md-6">
										<div class="grid-inner row align-items-center gutter-20">
											<div class="col-4">
												<div class="entry-image">
													<a href="#"><img src="/assets/images/blog/small/10.jpg" alt="Blog Single"></a>
												</div>
											</div>
											<div class="col-8">
												<div class="entry-title title-xs">
													<h3><a href="#">This is an Image Post</a></h3>
												</div>
												<div class="entry-meta">
													<ul>
														<li><i class="icon-calendar3"></i> 10th July 2021</li>
														<li><a href="#"><i class="icon-comments"></i> 12</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>

                                    <div class="entry col-12 col-md-6">
										<div class="grid-inner row align-items-center gutter-20">
											<div class="col-4">
												<div class="entry-image">
													<a href="#"><img src="/assets/images/blog/small/10.jpg" alt="Blog Single"></a>
												</div>
											</div>
											<div class="col-8">
												<div class="entry-title title-xs">
													<h3><a href="#">This is an Image Post</a></h3>
												</div>
												<div class="entry-meta">
													<ul>
														<li><i class="icon-calendar3"></i> 10th July 2021</li>
														<li><a href="#"><i class="icon-comments"></i> 12</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>

                                    <div class="entry col-12 col-md-6">
										<div class="grid-inner row align-items-center gutter-20">
											<div class="col-4">
												<div class="entry-image">
													<a href="#"><img src="/assets/images/blog/small/10.jpg" alt="Blog Single"></a>
												</div>
											</div>
											<div class="col-8">
												<div class="entry-title title-xs">
													<h3><a href="#">This is an Image Post</a></h3>
												</div>
												<div class="entry-meta">
													<ul>
														<li><i class="icon-calendar3"></i> 10th July 2021</li>
														<li><a href="#"><i class="icon-comments"></i> 12</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>

                                    <div class="entry col-12 col-md-6">
										<div class="grid-inner row align-items-center gutter-20">
											<div class="col-4">
												<div class="entry-image">
													<a href="#"><img src="/assets/images/blog/small/10.jpg" alt="Blog Single"></a>
												</div>
											</div>
											<div class="col-8">
												<div class="entry-title title-xs">
													<h3><a href="#">This is an Image Post</a></h3>
												</div>
												<div class="entry-meta">
													<ul>
														<li><i class="icon-calendar3"></i> 10th July 2021</li>
														<li><a href="#"><i class="icon-comments"></i> 12</a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>

								</div> -->
							</div>

						</div><!-- .postcontent end -->

					</div>

				</div>
			</div>
		</section><!-- #content end -->
    </div>
</template>

<script>


export default {
	data() {
		return {
			
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>

<style scoped>
.indent {
    padding-left:3em;
}
@media (min-device-width: 200px) and (max-device-width: 736px) { 
	.nostyle {
		max-height: 60%!important;
		max-width: 60%!important;
		height: auto!important;
		width:auto!important;
        margin: auto;
	}
}
</style>